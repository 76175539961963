import style from './uploaded-file.module.scss'
import Image from 'next/image';
import deleteIcon from '/public/assets/icons/close.png'

interface IUploadedFileProps {
    name: string;
    size: number;
    deleteFile: () => void;
}

const UploadedFileComponent = ({name, size, deleteFile }: IUploadedFileProps) => {
    function formatBytes(bytes: number, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }
    return (
        <div className={`${style.container}`}>
            <div className={`${style.container__item__name}`}>
                {name}
            </div>
            <div className={`${style.container__item}`}>
                <div className={`${style.container__item__size}`}>
                    {formatBytes(size)}
                </div>
                <div onClick={() => deleteFile()} className={`${style.container__item__delete}`}>
                    <Image src={deleteIcon} alt={name} width="24px" height="24px"/>
                </div>
            </div>
        </div>
    )
}

export default UploadedFileComponent;