import { useRouter } from "next/router";
import { config } from '../config';

// note: deployed to AWS S3 via admin.agilie.com
const DEFAULT_META_IMAGE_URL = 'https://d12zh9bqbty5wp.cloudfront.net/assets/f9e66e179b6747ae54108f82f8ade8b3c25d76fd30afde6c395822c530196169-1697702598849.png';

interface MetaProps {
    title: string;
    description: string;
    imageUrl?: string;
    pageUrl?: string;
}

export function MetaTags(props: MetaProps) {
    const {title = 'Agilie', description} = props;
    const router = useRouter();

    const pageUrl = props.pageUrl ?? router.asPath.split('?')?.[0];
    const imageUrl = props.imageUrl || DEFAULT_META_IMAGE_URL;

    return [
        <title key={'title'}>{title}</title>,

        <meta name="description" content={description} key={'description'}/>,

        <meta property="og:type" content="website" key={'og:type'}/>,
        <meta property="og:image" content={imageUrl} key={'og:image'}/>,
        <meta property="og:title" content={title} key={'og:title'}/>,
        <meta property="og:description" content={description} key={'og:description'}/>,
        <meta property="og:site_name" content="agilie.com" key={'og:site_name'}/>,

        <meta property="twitter:title" content={title} key={'twitter:title'}/>,
        <meta property="twitter:card" content="summary_large_image" key={'twitter:card'}/>,
        <meta property="twitter:description" content={description} key={'twitter:description'}/>,
        <meta property="twitter:image" content={imageUrl} key={'twitter:image'}/>,
        <link rel="canonical" href={`${config.HOST}${pageUrl}`} key={'pageLink'}/>
    ];
}
