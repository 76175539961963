import style from './start-project-form.module.scss';
import { Form, Formik, FormikProps } from 'formik'
import { string as yupString, object as yupObject } from 'yup';
import Image from 'next/image'
import { useEffect, useRef, useState } from 'react';
import { getCountyCode, sendFeedback } from '@services/feedback.service';
import UploadedFileComponent from '@components/shared/miscellaneous/uploaded-file-component/uploaded-file';
import PhoneInput from 'react-phone-input-2';
import preloader from '@styles/preloader.module.scss'
import Grecaptcha from '@libs/integrations/grecaptcha';
import { ReactSVG } from 'react-svg';

interface StartProjectFormProps {
    onSubmit: (success: boolean) => void;
    isOpen: boolean;
    currentFormStatus: string;
    isFormSubmitted: boolean;
    setFormSubmitted: (status: boolean) => void;
    isSmall?: boolean;
}

export const BUDGET_LIST = [`Up to $15,000`, `Up to $30,000`, `Up to $60,000`, `Up to $100,000`, `More than $100,000`,];
const acceptedFileTypes = ['application/pdf', 'text/plain', 'application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', '.doc', '.docx', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']

const StartProjectForm = ({ onSubmit, isOpen, currentFormStatus, isFormSubmitted, setFormSubmitted, isSmall }: StartProjectFormProps) => {
    const [budgetListActive, setBudgetLisActive] = useState(false);
    const [isCreatedByClient, setIsCreatedByClient] = useState(true);
    const [uploadedFiles, setUploadedFiles]: any[] = useState([]);
    const [fileSizeError, setFilesSizeError] = useState('');
    const [defaultPhoneCountry, setDefaultPhoneCountry] = useState('us');
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        getCountyCode().then((country) => {
            if (country.iso_code) {
                setDefaultPhoneCountry(country.iso_code);
            }
        });
    });

    useEffect(() => {
        document.addEventListener('click', handleOutsideClick);
        document.addEventListener('touchstart', handleOutsideClick);
    
        return () => {
            document.removeEventListener('click', handleOutsideClick);
            document.removeEventListener('touchstart', handleOutsideClick);
        };
    }, [budgetListActive]);

    const handleOutsideClick = (event: MouseEvent | TouchEvent) => {
        if (budgetListActive && dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
            setBudgetLisActive(false);
        }
    };

    function uploadFile() {
        let fileInput: any = document.getElementById('file-upload');
        let files: any[] = [...fileInput.files];
        files = files.filter((item) => {
            if ((item.size / (1024 * 1024)) > 5) {
                setFilesSizeError('Accepts files less than 5 MB');
            } else {
                setFilesSizeError('');
            }
            return (item.size / (1024 * 1024)) < 5;
        })
        setUploadedFiles([...uploadedFiles, ...files]);
    }

    function deleteFile(name: string, size: number) {
        setFilesSizeError('');
        setUploadedFiles(uploadedFiles.filter((item: any) => item.name !== name && item.size !== size));
    }

    return (
        <>
            <Grecaptcha />
            <Formik
                initialValues={{ name: '', email: '', company: '', phone: '', budget: '', details: '', file: '' }}
                isInitialValid={false}
                validationSchema={yupObject({
                    name: yupString().default('').required("Please enter your full name"),
                    email: yupString().default('')
                    .required("Please enter your email address")
                        .email('Please enter a valid email address'),
                    company: yupString().default(''),
                    phone: yupString(),
                    budget: yupString().default(''),
                    details: yupString().default('').required("Please enter your message"),
                    file: yupString(),
                })}
                onSubmit={async (values, actions) => {
                    actions.setSubmitting(true);
                    setFormSubmitted(true);
                    Promise.all(
                        [
                            sendFeedback(values.name, values.email, values.details, values.phone, isCreatedByClient, values.company, values.budget, uploadedFiles),
                            new Promise((resolve) => {
                                setTimeout(resolve, 500)
                            }),
                        ]
                    ).then(() => {
                        setFormSubmitted(false)
                        actions.setSubmitting(false)
                        onSubmit(true);
                        actions.resetForm();

                    }).catch(err => {
                        setFormSubmitted(false)
                        actions.setSubmitting(false)
                        onSubmit(false);
                    })
                }}
                validateOnBlur={false}
            >
                {formik => (
                    <Form className={`${isOpen && !isFormSubmitted && currentFormStatus === 'active' ? style.form : style.form_hidden} ${isSmall ? style.small : ''}`}>
                        <div className={style.form__block__item}>
                            <div className={`${style.form__block__item__title} ${style.required}`}>
                                Full name
                            </div>
                            <input {...formik.getFieldProps('name')}
                                placeholder="Your name" name="name"
                                className={`${style.form__input} ${formik.touched.name && formik.errors.name ? style.form__input_invalid : style.form__input}`}
                                type="text" />
                            {
                                formik.touched.name ?
                                    <div
                                        className={style.form__invalid}>{formik.errors.name}</div> : null
                            }
                        </div>
                        <div className={style.form__block__item}>
                          <div className={`${style.form__block__item__title} ${style.required}`}>
                                Email
                            </div>
                            <input {...formik.getFieldProps('email')}
                                placeholder="your@email.com" name="email"
                                className={`${style.form__input} ${formik.touched.email && formik.errors.email ? style.form__input_invalid : style.form__input}`}
                                type="email" />
                            {
                                formik.touched.email && formik.errors.email ?
                                    <div
                                        className={style.form__invalid}>{formik.errors.email}</div> : null
                            }
                        </div>
                        <div className={style.form__block__item}>
                            <div className={style.form__block__item__title}>
                                Phone number {isSmall ? '' : '(optional)'}
                            </div>
                            <div className={style.detail__form__block__input}>
                                <PhoneInput country={defaultPhoneCountry} value={formik.values.phone} onChange={(phone) => {
                                    formik.values.phone = phone;
                                }} inputClass={'detail__form__input'} buttonClass={'detail__form__btn'} dropdownClass={'detail__form__dropdown'}/>
                            </div>
                        </div>
                        <div className={style.form__block__item}>
                            <div className={style.form__block__item__title}>
                                Company {isSmall ? '' : '(optional)'}
                            </div>
                            <input {...formik.getFieldProps('company')}
                                   placeholder="Your company" name="company"
                                   className={`${style.form__input}`}
                                   type="text" />
                        </div>
                        <div className={`${style.form__block__item} ${style.budget}`} ref={dropdownRef}>
                            <div className={style.form__block__item__title}>
                                Budget {isSmall ? '' : '(optional)'}
                            </div>
                            <label htmlFor="budget-choice" className={`${style.budget__input}`}
                                 onClick={() => setBudgetLisActive(true)}>
                                <input {...formik.getFieldProps('budget')}
                                    placeholder="Your budget" name="budget"
                                    className={`${style.form__input} ${style.budget__input}`}
                                    type="text"
                                    autoComplete='off'
                                    list="budget" id="budget-choice"
                                />
                                <ReactSVG alt="arrow-icon"
                                          className={budgetListActive ? `${style.budget__arrow} ${style.budget__arrow_active}` : style.budget__arrow}
                                          sizes="16px"
                                          src='/assets/icons/icn-arrow.svg'/>
                            </label>
                            <div className={`${budgetListActive ? `${style.budget__list} ${style.budget__list_active}` : style.budget__list}`}>
                                {
                                    BUDGET_LIST.map((item: string, key: number) => {
                                        return (
                                            <div className={`${style.budget__list__item}`} onClick={() => {
                                                formik.setFieldValue('budget', item);
                                                setBudgetLisActive(false); 
                                            }} key={key}>
                                                {item}
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                        <div className={style.form__block__item}>
                          <div className={`${style.form__block__item__title} ${style.required}`}>
                                Project details
                            </div>
                            <textarea {...formik.getFieldProps('details')}
                                name="details"
                                placeholder="Your project details"
                                className={`${style.form__input} ${style.form__input_message} ${style.form__input_large} ${formik.touched.details && formik.errors.details ? style.form__input_invalid : style.form__input}`}
                            />
                            {
                                formik.touched.details && formik.errors.details ?
                                    <div
                                        className={style.form__invalid}>{formik.errors.details}</div> : null
                            }
                        </div>
                        <div className={`${style.form__block__item} ${style.form__block__item_flex}`}>
                            <input {...formik.getFieldProps('file')}
                                onChange={() => uploadFile()}
                                type="file" id={"file-upload"}
                                className={`${style.form__input_file}`}
                                multiple
                                accept={acceptedFileTypes.join(',')} />
                            <label htmlFor={'file-upload'} className={`${style.form__button_dnd}`}>
                                <div className={`${style.form__button_dnd__image}`}>
                                    <Image alt="attach" src={'/assets/icons/icn_attach.png'} width={"17px"} height={"20px"} />
                                </div>
                                <div id="file-name">
                                    Attach a file
                                </div>
                            </label>
                            <div className={style.form__block__item__text}>
                                <div className={style.form__block__item__text_black}>
                                    Accepted File Types:
                                </div>
                                <div className={style.form__block__item__text_second}>
                                    .pdf, .doc[x], .xls[x], .txt
                                </div>
                            </div>
                        </div>
                        <div className={`${style.form__invalid} ${style.file__error}`}>
                            {fileSizeError}
                        </div>
                        <div id="file-upload-place">
                            {
                                uploadedFiles.map((item: any, index: number) => {
                                    return <UploadedFileComponent key={index} name={item.name} size={item.size} deleteFile={() => deleteFile(item.name, item.size)} />
                                })
                            }
                        </div>
                        <button className={style.form__button}>Send</button>

                    </Form>
                )}
            </Formik>
            <div id="preloader" className={`${preloader.form__preloader} ${isFormSubmitted ? preloader.form__preloader_active : preloader.form__preloader_hidden}`}>
                <span className={`${preloader.spinner} ${preloader.spinner_large} ${preloader.spinner_green} ${preloader.spinner_slow}`}></span>
            </div>
        </>
    )
}

export default StartProjectForm;
