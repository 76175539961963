import FormSubmitStatus from '@components/shared/forms/form-submit-status/form-submit-status';
import StartProjectForm from '@components/shared/forms/start-project-form/start-project-form'
import { useState } from 'react';
import style from './index.module.scss'

enum FORM_STATUS {
    active = 'active',
    success = 'success',
    error = 'error',
}

interface IStartProjectComponentProps {
    title?: string;
    isSmall?: boolean;
}

export const StartProjectComponent = ({title, isSmall}: IStartProjectComponentProps) => {
    const [currentFormStatus, setFormStatus] = useState(FORM_STATUS.active);
    const [isFormOpen, setFormOpen] = useState(true);
    const [isFormSubmitted, setFormSubmitted] = useState(false);

    const onSubmit = (success: boolean) => {
        setFormOpen(false)
        if (success) {
            setFormStatus(FORM_STATUS.success);
        } else {
            setFormStatus(FORM_STATUS.error);
        }
    }
    return (
        <>
            {title ? <div className={`${style.title}`}>
                {title}
            </div> : ''}
            <StartProjectForm
              setFormSubmitted={(status: boolean) => setFormSubmitted(status)}
              isFormSubmitted={isFormSubmitted}
              isSmall={isSmall}
              onSubmit={(status: boolean) => onSubmit(status)}
              isOpen={isFormOpen} currentFormStatus={currentFormStatus}
            />
            {
                currentFormStatus !== FORM_STATUS.active &&
                <div className={style.status__wrapper}>
                    <FormSubmitStatus status={currentFormStatus === FORM_STATUS.success ? 'success' : 'failure'} />
                </div>
            }
        </>
    )
}
